html,
body {
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

*,
nav {
  box-sizing: border-box;
}

body {
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px;
}

#root {
  height: 100%;
}
